<script>
import RelatorioTemplate from "@/components/leiloes/components/relatorios/RelatorioTemplate"

export default {
  mixins: [RelatorioTemplate],
  name: 'RelatorioLeiloes',
  props: {
    data: {required: true}
  },
  created() {
    console.log(this.data)
    this.isLoading = false
    this.dados = this.data
    this.printView.printOptions.excel =  () => {
      this.$uloc.printView.emit(this.$root.wid, 'excel')
    }
  }
}
</script>
